import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Cylinder Calculator",
    link: "/cylinder-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/ellipsoid-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function CylinderCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let radius = useRef("")
  let height = useRef("")
  let unitSelector = useRef("")

  function calcVolume(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(
        <strong className="subtitle has-text-danger">
          Please Enter Radius Value
        </strong>
      )
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(
        <strong className="subtitle has-text-danger">
          Please Enter Height Value
        </strong>
      )
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)

      let volume = Math.PI * Math.pow(radiusValue, 2) * heightValue

      setresult(
        <strong className="mycolor subtitle">
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>
        </strong>
      )
    }
  }

  function calcArea(e) {
    let radiusValue = radius.current.value
    let heightValue = height.current.value
    let unit = unitSelector.current.value

    if (radiusValue === "" || isNaN(radiusValue)) {
      setresult(
        <strong className="subtitle has-text-danger">
          Please Enter Radius Value
        </strong>
      )
    } else if (heightValue === "" || isNaN(heightValue)) {
      setresult(
        <strong className="subtitle has-text-danger">
          Please Enter Height Value
        </strong>
      )
    } else {
      radiusValue = Number(radiusValue)
      heightValue = Number(heightValue)

      let lateralArea = 4 * Math.PI * Math.pow(radiusValue, 2)
      let surfaceArea =
        2 * Math.PI * radiusValue * heightValue +
        2 * Math.PI * Math.pow(radiusValue, 2)
      let basearea = Math.PI * Math.pow(radiusValue, 2)

      setresult(
        <strong className="mycolor subtitle">
          Lateral Area : {lateralArea.toFixed(2) + unit}
          <sup>2</sup> <br /> Total surface area :{" "}
          {surfaceArea.toFixed(2) + unit}
          <sup>2</sup>
          <br />
          Base area or Top area : {basearea.toFixed(2) + unit}
          <sup>2</sup>
        </strong>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Calculate Volume and Area of Cylinder"
        description="Cylinder Calculator Volume, Simple Cylinder Calculation volume, Cylinder lateral area calculator,  Total surface area calculator, Base area, or Top area Cylinder calculator."
        keywords={[
          "Cylinder calculator,Cylinder Calculate volume, Cylinder lateral area calculator,  Total surface area calculator, Base area or Top area Cylinder calculator, Cylinder Calculator Volume, Cylinder volume, Cylinder area, Cylinder lateral area, Cylinder surface area, how to calculate Cylinder volume, Cylinder, how Cylinder?, fast Cylinder calculator, fast Cylinder volume, fast Cylinder area .",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>Cylinder Volume and Area Calculator</Title>

        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Radius</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={radius}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Height</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={height}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>

            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>

            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg
                filename="cylender.png"
                alt="cylender radius and height calculator"
              />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>Calculate Cylinder Volume?</h3>
        <br />
        <p>
          <strong>
            The Volume of a right circular cylinder is: Volume = &pi; * radius
            <sup>2</sup> * height .
            <br />
            ex ; Volume = &pi; * 10<sup>2</sup> * 20 = 6283.19 m<sup>3</sup>
          </strong>
        </p>
        <br />

        <h3>How to get height of Circular Cylinder?</h3>
        <p>
          <strong>
            height Cylinder = Volume/&pi; * radius<sup>2</sup> .
          </strong>
        </p>
        <br />
        <h3>Get the radius of Cylinder :</h3>
        <p>
          <strong>
            Radius of Cylinder = &radic;
            <span>Volume / &pi; * height .</span>
          </strong>
        </p>

        <br />
        <h3>Cylinder area ?</h3>
        <br />
        <h4>The Lateral Area of a right circular cylinder :</h4>
        <p>
          <strong>
            lateral area = 2 * &pi; * radius * height .<br />
            ex : Lateral area = 2 * &pi; * 10 * 20 = 1256.64 m<sup>2</sup>
          </strong>
        </p>
        <br />
        <h4>The Surface Area of a right circular cylinder :</h4>
        <p>
          <strong>
            Surface area = ( 2 * &pi; * radius * height) + ( 2 * &pi; * radius
            <sup>2</sup>)
          </strong>
        </p>
        <br />
        <h4>The Base Area or Top area of circular cylinder :</h4>
        <p>
          <strong>
            Base area = &pi; * radius<sup>2</sup> .<br />
            Top area = &pi; * radius<sup>2</sup> .
          </strong>
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default CylinderCalculatorVolumeArea
